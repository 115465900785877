import axios from 'axios'
import {MessageBox, Message} from 'element-ui'
import store from '@/store'
import router from '@/router'
import {serverIp} from '../../public/config'
// create an axios instance
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    baseURL: serverIp, // url = base url + request url
    timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        if (store.getters.token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers['Authorization'] = localStorage.getItem("token")
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)
service.interceptors.response.use(async (res) => {
    //成功的回调
    if (res.data.code === 999) {
        localStorage.removeItem("token");
        localStorage.removeItem("menuList");
        localStorage.removeItem("userinfo");
        router.push('/login')
        // await store.dispatch('user/logout')
        // Message({
        //     message: res.data.msg || 'Error',
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        router.replace('/logout')
        return Promise.reject(new Error('请重新登录'))
    } else {
        return res
    }
}, (err) => {
    //失败的回调
    if (err === '未登录') {
        router.replace('/login')
    }
    // router.push('/login')

    // return Promise.reject(err)
})

export default service
