import request from '@/utils/request'

export function login(data){
    return request({
        url: "/login",
        method: "post",
        data:data
    })
}

// 修改密码
export function updatePwd(data){
    return request({
        url:"/resetpwd",
        method: "post",
        data: data
    })
}

// 退出登录
export function logout(){
    return request({
        url: "/logout",
        method: "post"
    })
}

// 用户第一登录密码太简单修改密码
export function userAlterPassword(data){
    return request({
        url: "/reset/ezpasswd",
        method: "post",
        data:data
    })
}
