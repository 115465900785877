import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./style/gloable.css"
import store from './store'
import router from "@/router";
import VueDynamicDialog from 'vue-dynamic-dialog'
import 'vue-dynamic-dialog/lib/vue-dynamic-dialog.css'
Vue.use(VueDynamicDialog)

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import {Base64} from "js-base64";
Vue.use(Base64)

import axios from '@/utils/request';
Vue.config.productionTip = false
// Vue.prototype.$axios = axios


Vue.use(ElementUI)

// App.use(ElementUI)
//
// new Vue({
//   render: h => h(App),
//   router,
//   store,
// }).$mount('#app')
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});
