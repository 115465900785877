import {login, logout, updatePwd} from '@/api/login'
import service from "@/utils/request";
import Router from "vue-router";
const getDefaultState = () => {
    return {
        token: localStorage.getItem("token"),
        menuList: JSON.parse(localStorage.getItem('menuList')),
        userinfo: {},
        tokenExpiration: localStorage.getItem("tokenExpiration"),
    }
}

const state = getDefaultState()

const mutations = {
    setToken: (state, token) => {
        state.token = token
    },
    setMenuList: (state, menuList) => {
        state.menuList = menuList
    },
    setUserinfo: (state,userinfo) =>{
        state.userinfo = userinfo
    },
    setTokenExpiration: (state,tokenExpiration) =>{
        state.tokenExpiration = tokenExpiration
    },
}

const actions = {
    // user login
    async login({ commit }, userInfo) {
        const { username, password } = userInfo
        let loginInfo={
            username:username,
            password:password
        }
        let res = await login(loginInfo)
        if(res.data.code === 200){
            let resData = res.data.data
            let userInfo= {
                userName: resData.users.usersName,
                roleId: resData.users.roleId,
                departmentNum: resData.users.departmentNum,
                companyid: resData.users.companyid
            }
            let menuList = resData.menuList
            let token = resData.token

            // 保存token和菜单数组
            localStorage.setItem("token",token)
            localStorage.setItem("menuList",JSON.stringify(menuList))
            localStorage.setItem("userinfo",JSON.stringify(userInfo))

            const now = new Date()
            const expirationTime = new Date(now.getTime() + 60*60*1000 )
            localStorage.setItem("tokenExpiration", JSON.stringify(expirationTime.getTime()))

            commit('setToken', token)
            commit('setMenuList',menuList)
            commit('setUserinfo',userInfo)
            commit('tokenExpiration',expirationTime.getTime())
            // 返回一个promise
            return 'ok'
        } else {
            return Promise.reject(new Error(res.data.msg))
        }
    },

    // 退出登录
    async logout({commit}){
        await logout();
        localStorage.removeItem("token")
        localStorage.removeItem("menuList")
        localStorage.removeItem("userinfo")
        localStorage.removeItem("tokenExpiration")
        location.reload();
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

