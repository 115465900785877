import Vue from 'vue'
import Router from 'vue-router'
import store from "@/store";

Vue.use(Router)

import { Message } from 'element-ui';

export const constantRoutes = [
    {
        path: '/login',
        name: "Login",
        component: () => import('@/views/login/index.vue')
    },
    {
        path: '/404',
        name: "404",
        component: () => import('@/views/404'),
    },
    {
        path: "/",
        name: "Home",
        redirect: '/home',
        component: () => import('@/layout/index.vue'),
        children: [
            // {path: "/assetSearch",component: () => import("@/views/taskAdministration/index.vue"),meta: {title: "定向资产 / 资产搜索"}},
            {path: "/userManage",component: () => import("@/views/userManage/index.vue"),meta: {title: "系统管理 / 账户管理"}},
            {path: "/changePwd",component: () => import('@/views/changePwd/index.vue'),meta: {title: "系统管理 / 修改密码"}},
            {path: "/systemUpdate",name:"systemUpdate",component: () => import('@/views/systemUpdate/index.vue'),meta: {title: "系统管理 / 系统更新"}},
            {path: "/person",name:"person",component: () => import('@/views/employeeAdministration/person/index.vue'),meta: {title: "员工管理 / 员工查询"}},
            {path: "/temporaryPerson",name:"temporaryPerson",component: () => import('@/views/employeeAdministration/temporaryPerson/index.vue'),meta: {title: "员工管理 / 临聘人员管理"}},
            {path: "/laborDispatch",name:"laborDispatch",component: () => import('@/views/employeeAdministration/laborDispatch/index.vue'),meta: {title: "员工管理 / 外包派遣人员"}},
            {path: "/payRoll",name: "payroll",component: () => import("@/views/salaryAdministration/payRoll/index.vue"),meta: {title: "薪酬管理 / 工资表"}},
            {path: "/productionOperationsSalary",component: () => import("@/views/salaryAdministration/productionOperationsSalary/index.vue"),meta: {title: "薪酬管理 / 生产运营部工资"}},
            {path: "/safetyTechnologySalary",component: () => import("@/views/salaryAdministration/safetyTechnologySalary/index.vue"),meta: {title: "薪酬管理 / 安全技术部工资"}},
            {path: "/safetyTechnologyExamine",component: () => import("@/views/salaryAdministration/safetyTechnologyExamine/index.vue"),meta: {title: "薪酬管理 / 安全技术部审核"}},
            {path: "/productionOperationsExamine",component: () => import("@/views/salaryAdministration/productionOperationsExamine/index.vue"),meta: {title: "薪酬管理 / 生产运营部审核"}},
            {path: "/hrExamine",component: () => import("@/views/salaryAdministration/hrExamine/index.vue"),meta: {title: "薪酬管理 / 人力资源部审核"}},
            {path: "/financialExamine",component: () => import("@/views/salaryAdministration/financialExamine/index.vue"),meta: {title: "薪酬管理 / 财务部审核"}},
            {path: "/leadersExamine",component: () => import("@/views/salaryAdministration/leadersExamine/index.vue"),meta: {title: "薪酬管理 / 分管领导审核"}},
            {path: "/annualSummary",component: () => import("@/views/salaryAdministration/annualSummary/index.vue"),meta: {title: "薪酬管理 / 年度汇总"}},
            {path: "/basicSalary",component: () => import("@/views/salaryAdministration/basicSalary/index.vue"),meta: {title: "薪酬管理 / 基本工资"}},
            {path: "/salarySettings",component: () => import("@/views/salaryAdministration/salarySettings/index.vue"),meta: {title: "薪酬管理 / 增减工资设置"}},
            {path: "/traineeSalary",component: () => import("@/views/salaryAdministration/traineeSalary/index.vue"),meta: {title: "薪酬管理 / 实习人员工资"}},
            {path: "/lineSalary",name: "lineSalary",component: () => import("@/views/salaryAdministration/lineSalary/index.vue"),meta: {title: "薪酬管理 / 线路工资"}},
            {path: "/askLeave",component: () => import("@/views/attendanceAdministration/askLeave/index.vue"),meta: {title: "考勤管理 / 请假管理"}},
            {path: "/noclockRecord",component: () => import("@/views/attendanceAdministration/noclockRecord/index.vue"),meta: {title: "考勤管理 / 免打卡管理"}},
            {path: "/addWork",component: () => import("@/views/attendanceAdministration/addWork/index.vue"),meta: {title: "考勤管理 / 加班管理"}},
            {path: "/checkPeriod",component: () => import("@/views/attendanceAdministration/checkPeriod/index.vue"),meta: {title: "考勤管理 / 打卡时段管理"}},
            {path: "/festival",component: () => import("@/views/attendanceAdministration/festival/index.vue"),meta: {title: "考勤管理 / 节假日管理"}},
            {path: "/weekendsOt",component: () => import("@/views/attendanceAdministration/weekendsOt/index.vue"),meta: {title: "考勤管理 / 周末加班统计"}},
            {path: "/leaveform",component: () => import("@/views/attendanceAdministration/leaveform/index.vue"),meta: {title: "考勤管理 / 请销假统计"}},
            {path: "/attendancestat",component: () => import("@/views/attendanceAdministration/attendancestat/index.vue"),meta: {title: "考勤管理 / 考勤统计"}},
            {path: "/askleaveapproval",component: () => import("@/views/deptapproval/askleaveapproval/index.vue"),meta: {title: "部门审批 / 请假审批"}},
            {path: "/addworkapproval",component: () => import("@/views/deptapproval/addworkapproval/index.vue"),meta: {title: "部门审批 / 加班审批"}},
            {path: "/noclockapproval",component: () => import("@/views/deptapproval/noclockapproval/index.vue"),meta: {title: "部门审批 / 免打卡审批"}},
            {path: "/askleaveapproval",component: () => import("@/views/masterapproval/askleaveapproval/index.vue"),meta: {title: "分管领导审批 / 请假审批"}},
            {path: "/addworkapproval",component: () => import("@/views/masterapproval/addworkapproval/index.vue"),meta: {title: "分管领导审批 / 加班审批"}},
            {path: "/noclockapproval",component: () => import("@/views/masterapproval/noclockapproval/index.vue"),meta: {title: "分管领导审批 / 免打卡审批"}},
            {path: "/askleaveapproval",component: () => import("@/views/manageapproval/askleaveapproval/index.vue"),meta: {title: "总经理审批 / 请假审批"}},
            {path: "/askleaveapproval",component: () => import("@/views/CEOapproval/askleaveapproval/index.vue"),meta: {title: "董事长审批 / 请假审批"}},
            {path: "/dispatch",component: () => import("@/views/companydispatch/dispatch/index.vue"),meta: {title: "劳务外包派遣 / 劳务外包派遣"}},
            {path: "/payRoll/dataEntry_middle",name: "dataEntry_middle",component: () => import("@/views/salaryAdministration/payRoll/dataEntry_middle.vue"),meta: {title: "薪酬管理 / 工资表 / 录入数据-中层"}},
            {path: "/payRoll/dataEntry_logistics",name: "dataEntry_logistics",component: () => import("@/views/salaryAdministration/payRoll/dataEntry_logistics.vue"),meta: {title: "薪酬管理 / 工资表 / 录入数据-后勤"}},
            {path: "/payRoll/dataEntry_units",name: "dataEntry_units",component: () => import("@/views/salaryAdministration/payRoll/dataEntry_units.vue"),meta: {title: "薪酬管理 / 工资表 / 录入数据-机关"}},
            {path: "/payRoll/dataEntry_line",name: "dataEntry_line",component: () => import("@/views/salaryAdministration/payRoll/dataEntry_line.vue"),meta: {title: "薪酬管理 / 工资表 / 录入数据-线路"}},
            {path: "/payRoll/dataEntry_leader",name: "dataEntry_leader",component: () => import("@/views/salaryAdministration/payRoll/dataEntry_leader.vue"),meta: {title: "薪酬管理 / 工资表 / 录入数据-班子"}},
            {path: "/payRoll/dataEntry_chairman",name: "dataEntry_chairman",component: () => import("@/views/salaryAdministration/payRoll/dataEntry_chairman.vue"),meta: {title: "薪酬管理 / 工资表 / 录入数据-工会主席"}},
            {path: "/productionOperationsSalary",name: "productionOperationsSalary",component: () => import("@/views/salaryAdministration/productionOperationsSalary/index.vue"),meta: {title: "薪酬管理 / 生产运营部工资"}},
            {path: "/productionOperationsSalary/poDataEntry",name: "poDataEntry",component: () => import("@/views/salaryAdministration/productionOperationsSalary/poDataEntry.vue"),meta: {title: "薪酬管理 / 生产运营部工资 / 生产运营部工资录入数据"}},
            {path: "/safetyTechnologySalary",name: "safetyTechnologySalary",component: () => import("@/views/salaryAdministration/safetyTechnologySalary/index.vue"),meta: {title: "薪酬管理 / 安全技术部工资"}},
            {path: "/safetyTechnologySalary/stDataEntry",name: "stDataEntry",component: () => import("@/views/salaryAdministration/safetyTechnologySalary/stDataEntry.vue"),meta: {title: "薪酬管理 / 安全技术部工资 / 安全技术部工资录入数据"}},
            {path: "/safetyTechnologyExamine",name:"safetyTechnologyExamine",component: () => import("@/views/salaryAdministration/safetyTechnologyExamine/index.vue"),meta: {title: "薪酬管理 / 安全技术部审核"}},
            {path: "/stExamine",name:"stExamine",component: () => import("@/views/salaryAdministration/safetyTechnologyExamine/stExamine.vue"),meta: {title: "薪酬管理 / 安全技术部审核 / 审核操作"}},
            {path: "/productionOperationsExamine",name:"productionOperationsExamine",component: () => import("@/views/salaryAdministration/productionOperationsExamine/index.vue"),meta: {title: "薪酬管理 / 生产运营部审核"}},
            {path: "/poExamine",name:"poExamine",component: () => import("@/views/salaryAdministration/productionOperationsExamine/poExamine.vue"),meta: {title: "薪酬管理 / 生产运营部审核 / 审核操作"}},
            {path: "/hrExamine",name:"hrExamine",component: () => import("@/views/salaryAdministration/hrExamine/index.vue"),meta: {title: "薪酬管理 / 人力资源部审核"}},
            {path: "/HRExamine_middle",name:"HRExamine_middle",component: () => import("@/views/salaryAdministration/hrExamine/HRExamine_middle.vue"),meta: {title: "薪酬管理 / 人力资源部审核 / 审核操作-中层"}},
            {path: "/HRExamine_logistics",name:"HRExamine_logistics",component: () => import("@/views/salaryAdministration/hrExamine/HRExamine_logistics.vue"),meta: {title: "薪酬管理 / 人力资源部审核 / 审核操作-后勤"}},
            {path: "/HRExamine_units",name:"HRExamine_units",component: () => import("@/views/salaryAdministration/hrExamine/HRExamine_units.vue"),meta: {title: "薪酬管理 / 人力资源部审核 / 审核操作-机关"}},
            {path: "/HRExamine_line",name:"HRExamine_line",component: () => import("@/views/salaryAdministration/hrExamine/HRExamine_line.vue"),meta: {title: "薪酬管理 / 人力资源部审核 / 审核操作-线路"}},
            {path: "/HRExamine_leader",name:"HRExamine_leader",component: () => import("@/views/salaryAdministration/hrExamine/HRExamine_leader.vue"),meta: {title: "薪酬管理 / 人力资源部审核 / 审核操作-班子"}},
            {path: "/HRExamine_chairman",name:"HRExamine_chairman",component: () => import("@/views/salaryAdministration/hrExamine/HRExamine_chairman.vue"),meta: {title: "薪酬管理 / 人力资源部审核 / 审核操作-工会主席"}},
            {path: "/financialExamine",name:"financialExamine",component: () => import("@/views/salaryAdministration/financialExamine/index.vue"),meta: {title: "薪酬管理 / 财务部审核"}},
            {path: "/fiExamine_middle",name:"fiExamine_middle",component: () => import("@/views/salaryAdministration/financialExamine/fiExamine_middle.vue"),meta: {title: "薪酬管理 / 财务部审核 / 审核操作-中层"}},
            {path: "/fiExamine_logistics",name:"fiExamine_logistics",component: () => import("@/views/salaryAdministration/financialExamine/fiExamine_logistics.vue"),meta: {title: "薪酬管理 / 财务部审核 / 审核操作-后勤"}},
            {path: "/fiExamine_units",name:"fiExamine_units",component: () => import("@/views/salaryAdministration/financialExamine/fiExamine_units.vue"),meta: {title: "薪酬管理 / 财务部审核 / 审核操作-机关"}},
            {path: "/fiExamine_line",name:"fiExamine_line",component: () => import("@/views/salaryAdministration/financialExamine/fiExamine_line.vue"),meta: {title: "薪酬管理 / 财务部审核 / 审核操作-线路"}},
            {path: "/fiExamine_leader",name:"fiExamine_leader",component: () => import("@/views/salaryAdministration/financialExamine/fiExamine_leader.vue"),meta: {title: "薪酬管理 / 财务部审核 / 审核操作-班子"}},
            {path: "/fiExamine_chairman",name:"fiExamine_chairman",component: () => import("@/views/salaryAdministration/financialExamine/fiExamine_chairman.vue"),meta: {title: "薪酬管理 / 财务部审核 / 审核操作-工会主席"}},
            {path: "/leadersExamine",name:"leadersExamine",component: () => import("@/views/salaryAdministration/leadersExamine/index.vue"),meta: {title: "薪酬管理 / 分管领导审核"}},
            {path: "/leaExamine_middle",name:"leaExamine_middle",component: () => import("@/views/salaryAdministration/leadersExamine/leaExamine_middle.vue"),meta: {title: "薪酬管理 / 分管领导审核 / 审核操作-中层"}},
            {path: "/leaExamine_logistics",name:"leaExamine_logistics",component: () => import("@/views/salaryAdministration/leadersExamine/leaExamine_logistics.vue"),meta: {title: "薪酬管理 / 分管领导审核 / 审核操作-后勤"}},
            {path: "/leaExamine_units",name:"leaExamine_units",component: () => import("@/views/salaryAdministration/leadersExamine/leaExamine_units.vue"),meta: {title: "薪酬管理 / 分管领导审核 / 审核操作-机关"}},
            {path: "/leaExamine_line",name:"leaExamine_line",component: () => import("@/views/salaryAdministration/leadersExamine/leaExamine_line.vue"),meta: {title: "薪酬管理 / 分管领导审核 / 审核操作-线路"}},
            {path: "/leaExamine_leader",name:"leaExamine_leader",component: () => import("@/views/salaryAdministration/leadersExamine/leaExamine_leader.vue"),meta: {title: "薪酬管理 / 分管领导审核 / 审核操作-班子"}},
            {path: "/leaExamine_chairman",name:"leaExamine_chairman",component: () => import("@/views/salaryAdministration/leadersExamine/leaExamine_chairman.vue"),meta: {title: "薪酬管理 / 分管领导审核 / 审核操作-工会主席"}},
            {path: "/annualSummary",name:"annualSummary",component: () => import("@/views/salaryAdministration/annualSummary/index.vue"),meta: {title: "薪酬管理 / 年度汇总"}},
            {path: "/itemizedStatistics",name:"itemizedStatistics",component: () => import("@/views/salaryAdministration/itemizedStatistics/index.vue"),meta: {title: "薪酬管理 / 分项统计"}},
            {path: "/basicSalary",name:"basicSalary",component: () => import("@/views/salaryAdministration/basicSalary/index.vue"),meta: {title: "薪酬管理 / 基本工资"}},
            {path: "/salarySettings",name:"salarySettings",component: () => import("@/views/salaryAdministration/salarySettings/index.vue"),meta: {title: "薪酬管理 / 增减工资设置"}},
            {path: "/traineeSalary",name:"traineeSalary",component: () => import("@/views/salaryAdministration/traineeSalary/index.vue"),meta: {title: "薪酬管理 / 实习人员工资"}},
            {path: "/askLeave",name:"askLeave",component: () => import("@/views/attendanceAdministration/askLeave/index.vue"),meta: {title: "考勤管理 / 请假管理"}},
            {path: "/noclockRecord",name:"noclockRecord",component: () => import("@/views/attendanceAdministration/noclockRecord/index.vue"),meta: {title: "考勤管理 / 免打卡管理"}},
            {path: "/addWork",name:"addWork",component: () => import("@/views/attendanceAdministration/addWork/index.vue"),meta: {title: "考勤管理 / 加班管理"}},
            {path: "/checkPeriod",name:"checkPeriod",component: () => import("@/views/attendanceAdministration/checkPeriod/index.vue"),meta: {title: "考勤管理 / 打卡时段管理"}},
            {path: "/festival",name:"festival",component: () => import("@/views/attendanceAdministration/festival/index.vue"),meta: {title: "考勤管理 / 节假日管理"}},
            {path: "/dutySche",name:"dutySche",component: () => import("@/views/attendanceAdministration/dutySche/index.vue"),meta: {title: "考勤管理 / 排班管理"}},
            {path: "/dutySche/dutySche_detail",name:"dutySche_detail",component: () => import("@/views/attendanceAdministration/dutySche/dutySche_detail"),meta: {title: "考勤管理 / 排班管理 / 排班详情"}},
            {path: "/trainingRecords",name:"trainingRecords",component: () => import("@/views/othersManagement/trainingRecords/index.vue"),meta: {title: "其它管理 / 培训管理"}},
            {path: "/driverReserve",name:"driverReserve",component: () => import("@/views/othersManagement/driverReserve/index.vue"),meta: {title: "其它管理 / 驾驶员储备库"}},
            ]
    },
    {
        path: "/changeEzPwd",
        name: "ChangeEzPwd",
        component: () => import('@/views/User/LoginGoAlterPassword/index.vue')
        // },
        // {
        //     path: "condition",
        //     name: "Condition",
        //     component: () => import('@/views/condition/index.vue')
    },
]


const createRouter = () => new Router({
    mode: 'history', // require service support
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
})

const router = createRouter(
    {
    routes: [

    ]
}
)
//
//提供一个重置路由的方法
export const resetRouter = () => {
    router.matcher = createRouter()
}
const checkTokenValidity = () => {
    const token = localStorage.getItem("token")
    const tokenExpiration = localStorage.getItem("tokenExpiration")
    const now = new Date()

    if (!token || now.getTime() > parseInt(tokenExpiration)) {
        localStorage.removeItem("token")
        localStorage.removeItem("tokenExpiration")
        localStorage.removeItem("menuList")
        localStorage.removeItem("userinfo")

        if (window.location.pathname !== "/login" && window.location.pathname !== "/changeEzPwd") {
            Message.warning("请重新登录");
            router.push("/login");
        }
    }
}

setInterval(checkTokenValidity, 60)
// 路由守卫
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("token")
    const tokenExpiration = localStorage.getItem("tokenExpiration")
    const now = new Date()

    if (to.path === "/login") {
        if ((token || now.getTime() < parseInt(tokenExpiration)) && now.getTime() < parseInt(tokenExpiration)) {
            next("/") // 如果已登录且 Token 未过期，则重定向到首页
        } else {
            next()
        }
    } else {
        if ((!token || now.getTime() > parseInt(tokenExpiration)) && to.path !== "/changeEzPwd") {
            localStorage.removeItem("token")
            localStorage.removeItem("tokenExpiration")
            localStorage.removeItem("menuList")
            localStorage.removeItem("userinfo")
            Message.warning("请重新登录");
            next("/login")
        } else {
            next()
        }
    }
})

router.afterEach((to, from) => {
    const token = localStorage.getItem("token");
    if (token && to.path !== "/login") {
        // 获取当前时间并添加一小时（3600000 毫秒）
        const newExpiration = new Date().getTime() + 3600000;
        localStorage.setItem("tokenExpiration", newExpiration.toString());
    }
});



// 注意：刷新页面会导致页面路由重置
export const setRoutes = () => {
    const storeMenus = localStorage.getItem("menuList");
    if (storeMenus) {
        //获取当前的路由对象名称数组
        const currentRoutesNames = router.getRoutes().map(v => v.name)
        console.log(currentRoutesNames)
        if (!currentRoutesNames.includes("Manager")) {
            //动态拼装路由
            const manageMenus = {
                path: "/home",
                name: "Manager",
                component: () => import('@/layout/index.vue'),
                children: [],
                meta: {title: "首页"}
            }
            const menus = JSON.parse(storeMenus)
            menus.forEach(item => {
                if (item.children.length >= 0) {
                    item.children.forEach(item1 => {
                        if (item1.url) {
                            let itemMenus1 =
                                {
                                    path: item1.url,
                                    name: item1.url.replace("/", ""),
                                    component: () => import('../views' + item1.url + '/index.vue'),
                                    meta: {title: item1.moduleName}
                                }
                            manageMenus.children.push(itemMenus1)
                        }
                    })
                }

                // if(item.path){
                //     let itemMenus = {path:item.url.replace("/",""),name:item.moduleName,component:()=>import('../views/'+item.pagePath+'.vue')}
                //     manageMenus.children.push(itemMenus)
                // }else if(item.children.length){
                //     item.children.forEach(item1=>{
                //         if(item1.path){
                //             let itemMenus1 = {path:item1.path.replace("/",""),name:item1.moduleName,component:()=>import('../views/'+item1.pagePath+'.vue')}
                //             manageMenus.children.push(itemMenus1)
                //         }
                //     })
                // }
            })
            //动态添加到现在的路由对象
            router.addRoute(manageMenus)
        }
    }
}

//重置则再次set路由
setRoutes()


export default router

